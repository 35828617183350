.multi-select-custom {
   width: 50% !important
      
  }

  .moveall,
  .removeall {
    border: 1px solid #ccc !important;
    
    &:hover {
      background: #efefef;
    }
  }
  
  // Only included because button labels aren't showing 
  
  .moveall::after {
    content: attr(title);
    
  }
  
  .removeall::after {
    content: attr(title);
  }
  
  // Custom styling form
  .form-control option {
      padding: 10px;
      border-bottom: 1px solid #efefef;
  }
  .dual-list {
    max-height: 600px;
    min-height: 500px;
}

  .dual-list .list-group {
    margin-top: 8px;
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
}

.list-left li, .list-right li {
    cursor: pointer;
}

.list-arrows {
    padding-top: 100px;
}

    .list-arrows button {
        margin-bottom: 20px;
    }